// Import our CSS
import styles from '../css/app.pcss';

function is_touch_device() {
    // https://codepen.io/Ferie/pen/vQOMmO
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    var mq = function(query) {
        return window.matchMedia(query).matches;
    }
    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
        return true;
    }
    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
}
if (is_touch_device()) { document.documentElement.classList.add('touch'); }

document.documentElement.classList.add('js');


// Core JS file
import * as PhotoSwipe from 'photoswipe';
// UI JS file
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'

import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

//import lazySizes from 'lazysizes';



var initPhotoSwipeFromDOM = function(gallerySelector) {

    // parse slide data (url, title, size ...) from DOM elements 
    // (children of gallerySelector)
    var parseThumbnailElements = function(el) {
        var thumbElements = el.childNodes,
            numNodes = thumbElements.length,
            items = [],
            figureEl,
            linkEl,
            size,
            item;
    
        for(var i = 0; i < numNodes; i++) {

            figureEl = thumbElements[i]; // <figure> element

            // include only element nodes 
            if(figureEl.nodeType !== 1) {
                continue;
            }

            linkEl = figureEl.children[0]; // <a> element

            // include only link elements 
            if(linkEl.nodeName !== 'A') {
                continue;
            }

            size = linkEl.getAttribute('data-size').split('x');

            // create slide object
            item = {
                src: linkEl.getAttribute('href'),
                w: parseInt(size[0], 10),
                h: parseInt(size[1], 10)
            };

            if(figureEl.children.length > 1) {
                // <figcaption> content
                item.title = figureEl.children[1].innerHTML; 
            }

            if(linkEl.children.length > 0) {
                // <img> thumbnail element, retrieving thumbnail url
                item.msrc = linkEl.children[0].getAttribute('src');
            } 

            item.el = figureEl; // save link to element for getThumbBoundsFn
            items.push(item);
        }

        return items;
    };

    // find nearest parent element
    var closest = function closest(el, fn) {
        return el && ( fn(el) ? el : closest(el.parentNode, fn) );
    };

    // triggers when user clicks on thumbnail
    var onThumbnailsClick = function(e) {

        e.preventDefault();

        e = e || window.event;
        e.preventDefault ? e.preventDefault() : e.returnValue = false;

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function(el) {
            return (el.tagName && el.tagName.toUpperCase() === 'FIGURE' && el.classList.contains('image'));
        });

        if(!clickedListItem) {
            return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
            childNodes = clickedListItem.parentNode.childNodes,
            numChildNodes = childNodes.length,
            nodeIndex = 0,
            index;

        for (var i = 0; i < numChildNodes; i++) {
            if(childNodes[i].nodeType !== 1) { 
                continue; 
            }

            if(childNodes[i] === clickedListItem) {
                index = nodeIndex;
                break;
            }
            nodeIndex++;
        }



        if(index >= 0) {
            // open PhotoSwipe if valid index found
            openPhotoSwipe( index, clickedGallery );
        }
        return false;
    };

    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    var photoswipeParseHash = function() {
        var hash = window.location.hash.substring(1),
        params = {};

        if(hash.length < 5) {
            return params;
        }

        var vars = hash.split('&');
        for (var i = 0; i < vars.length; i++) {
            if(!vars[i]) {
                continue;
            }
            var pair = vars[i].split('=');  
            if(pair.length < 2) {
                continue;
            }           
            params[pair[0]] = pair[1];
        }

        if(params.gid) {
            params.gid = parseInt(params.gid, 10);
        }

        return params;
    };

    var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
        var pswpElement = document.querySelectorAll('.pswp')[0],
            gallery,
            options,
            items;

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {

            shareEl: false,

            // define gallery index (for URL)
            galleryUID: galleryElement.getAttribute('data-pswp-uid'),

            getThumbBoundsFn: function(index) {
                // See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect(); 

                return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
            }

        };

        // PhotoSwipe opened from URL
        if(fromURL) {
            if(options.galleryPIDs) {
                // parse real index when custom PIDs are used 
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for(var j = 0; j < items.length; j++) {
                    if(items[j].pid == index) {
                        options.index = j;
                        break;
                    }
                }
            } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1;
            }
        } else {
            options.index = parseInt(index, 10);
        }

        // exit if index not found
        if( isNaN(options.index) ) {
            return;
        }

        if(disableAnimation) {
            options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
    };

    // loop through all gallery elements and bind events
    var galleryElements = document.querySelectorAll( gallerySelector );

    for(var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute('data-pswp-uid', i+1);
        galleryElements[i].onclick = onThumbnailsClick;
    }

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    var hashData = photoswipeParseHash();
    if(hashData.pid && hashData.gid) {
        openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
    }
};


document.addEventListener("DOMContentLoaded", function() {

    const htmlTag = document.querySelector("html");
    const bodyTag = document.querySelector("body");
    const toggleMenu = document.querySelector(".site-nav button");
    const menu = document.querySelector(".site-nav .nav-items");;

    let shopLink = document.querySelector(".site-nav .nav-items .jewellery");
    const wholesaleLink = document.querySelector(".site-nav .nav-items .wholesale");
    if (wholesaleLink) shopLink = wholesaleLink;
    console.log(shopLink);

    const shopNav = document.querySelector(".shop-nav");
    // const wholesaleNav = document.querySelector(".wholesale-nav");
    const shopNavOverlay = document.querySelector(".shop-nav-overlay");

    let toggleMobileNav = function() {
        const open = JSON.parse(toggleMenu.getAttribute("aria-expanded"));
        toggleMenu.setAttribute("aria-expanded", !open);
        open ? bodyTag.classList.remove("js-open") : bodyTag.classList.add("js-open");
        menu.hidden = !menu.hidden;
    }
    let toggleShopNav = function() {
        const open = shopNav.hasAttribute("hidden") ? true : false;
        open ? bodyTag.classList.add("js-nav-open") : bodyTag.classList.remove("js-nav-open");
        open ? shopLink.classList.add("js-nav-open") : shopLink.classList.remove("js-nav-open");
        shopNav.hidden = !shopNav.hidden;
        shopNavOverlay.hidden = !shopNavOverlay.hidden;
    }
    // let toggleWholesaleNav = function() {
    //     const open = wholesaleNav.hasAttribute("hidden") ? true : false;
    //     open ? bodyTag.classList.add("js-nav-open") : bodyTag.classList.remove("js-nav-open");
    //     open ? wholesaleLink.classList.add("js-nav-open") : wholesaleLink.classList.remove("js-nav-open");
    //     wholesaleNav.hidden = !wholesaleNav.hidden;
    //     shopNavOverlay.hidden = !shopNavOverlay.hidden;
    // }

    if (toggleMenu) {
        menu.setAttribute("hidden","true");
        toggleMenu.addEventListener("click", function () {
          // const open = JSON.parse(toggleMenu.getAttribute("aria-expanded"));
          // toggleMenu.setAttribute("aria-expanded", !open);
          // open ? bodyTag.classList.remove("js-open") : bodyTag.classList.add("js-open");
          // menu.hidden = !menu.hidden;
          toggleMobileNav();
          toggleShopNav();
          // shopLink && toggleShopNav();
          // wholesaleLink && toggleShopNav();
        });
    }

    // do some preamble to hide stuff
    shopNav.setAttribute("hidden","true");
    // wholesaleNav.setAttribute("hidden","true");
    shopNavOverlay.setAttribute("hidden","true");
    shopNavOverlay.classList.remove("hidden");
    shopNavOverlay.addEventListener("click", function () {
            shopLink && shopLink.click();
            // wholesaleLink && wholesaleLink.click();
        });

    if (shopLink) {
        shopLink.addEventListener("click", function (event) {
            event.preventDefault();
            toggleShopNav();
            toggleMobileNav();
        });
    }

    // Create a new observer
    let shopNavObserver = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
            const open = shopNav.hasAttribute("hidden") ? false : true;
            if(!entry.isIntersecting && open) {
                shopLink.click();
            }
        });
    });
    shopNavObserver.observe(shopNav);


    const homePoster = document.querySelector(".home-poster");

    if (homePoster) {
        let homeObserver = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                // console.log(entry.isIntersecting);
                if(!entry.isIntersecting) {
                    bodyTag.classList.add("js-home-scrolled");
                } else {
                    bodyTag.classList.remove("js-home-scrolled");
                }
            });
        }, { threshold: 0.9 } );
        homeObserver.observe(homePoster);
    }



    const expandingRegions = document.querySelectorAll(".expandable-info, .expandable-section");

    Array.prototype.forEach.call(expandingRegions, function(region) { 
        const button = region.previousElementSibling;
        // console.log(region.hidden);
        // region.setAttribute("hidden","true");
        button.addEventListener("click",function(){
            const open = JSON.parse(button.getAttribute("aria-expanded"));
            button.setAttribute("aria-expanded", !open);
            region.hidden = !region.hidden;
        });

    });

    const taxConf = document.querySelector("#non-uk-tax");
    const payPalForm = document.querySelector("#paypal-form");
    const payButton = document.querySelector("#cart-proceed");

    if (taxConf) {
        taxConf.addEventListener("change", function(e){
            payPalForm.hidden = !taxConf.checked;
            payButton.disabled = !taxConf.checked;
            // console.log(taxConf.checked)
        });
    }
    


    initPhotoSwipeFromDOM('.gallery');

});





// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}